<template>
  <v-dialog
    :value="isAddStaffModal"
    width="650"
    @click:outside="$emit('changeAddStaffModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('changeAddStaffModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title">Создать сотрудника</p>

      <v-form
        ref="form"
        v-model="valid"
        class="mt-9"
      >
        <div class="row">
          <div class="col-sm-6 py-0">
            <v-text-field
              v-model="user.user.name"
              label="Имя"
              class="rounded-lg"
              required
              :rules="nameRules"
              outlined
              rounded
            ></v-text-field>
          </div>

          <div class="col-sm-6 py-0">
            <v-select
              :items="getFilterPositions"
              label="Должность"
              required
              :rules="nameRules"
              outlined
              rounded
              v-model="user.position"
              item-value="id"
              item-text="value"
              class="rounded-lg"
            ></v-select>
          </div>
        </div>

        <div class="row mb-1px">
          <div class="col-sm-6 py-0">
            <v-text-field
              v-model="user.user.email"
              :rules="emailRules"
              label="E-mail"
              class="rounded-lg"
              required
              outlined
              rounded
            ></v-text-field>
          </div>

          <div class="col-sm-6 py-0">
            <v-select
              :items="OFFICES"
              label="Офис"
              required
              :rules="nameRules"
              outlined
              rounded
              v-model="user.office"
              item-value="value"
              item-text="value"
              class="rounded-lg"
            ></v-select>
          </div>
        </div>

        <v-checkbox
          v-model="user.is_outsource"
          label="Аутстаф"
          class="mt-0"
          required
          outlined
          rounded
        ></v-checkbox>

        <div
          class="row"
          v-if="user.is_outsource"
        >
          <div class="col-sm-6 py-0">
            <v-text-field
              v-model="user.payment_per_hour"
              label="Покупка в час"
              class="rounded-lg"
              required
              :rules="nameRules"
              outlined
              rounded
            ></v-text-field>
          </div>
        </div>

        <div class="card-actions d-flex justify-center">
          <v-btn
            color="black"
            dark
            class="mt-6 modal__btn"
            x-large
            @click="save"
          >
            Создать
          </v-btn>
        </div>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { OFFICES } from '@/core/constants/offices';
import notification from '@/mixins/notification';

export default {
  name: 'AddStaffModal',
  mixins: [notification],

  data() {
    return {
      valid: true,
      isPassword: false,
      user: {
        user: {
          name: null,
          email: null,
        },
        office: null,
        is_outsource: false,
        payment_per_hour: null,
        position: null,
      },
      OFFICES,
    };
  },

  created() {
    this.getFilters();
  },

  computed: {
    ...mapState(['filters']),
    getFilterPositions() {
      return this.filters?.positions.filter((el) => el.value !== 'PM') || [];
    },
  },

  methods: {
    ...mapActions('staff', ['createUser']),
    ...mapActions(['getFilters']),

    async save() {
      await this.$refs.form.validate();
      if (this.valid) {
        const res = await this.createUser({
          ...this.user,
          payment_per_hour: this.user.is_outsource ? this.user.payment_per_hour : 0,
        });
        if (res.response?.status === 400) {
          if (res.response.data.email) {
            const error = res.response.data.email[0];
            if (error === 'Is Invalid') this.showErrorNotification('Некорректный email');
            if (error === 'Domain is invalid') this.showErrorNotification('Некорректный домен');
            if (error === 'Employee with this email already exists') this.showErrorNotification('Данный сотрудник уже зарегистрирован');
            if (error === 'PM with this email already exists') this.showErrorNotification('Данный сотрудник уже зарегистрирован');
            else this.showErrorNotification('Некорректный email');
          }
        } else {
          this.$refs.form.reset();
          this.user.is_outsource = false;
          this.$emit('changeAddStaffModal', false);
        }
      }
    },
  },

  props: {
    isAddStaffModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
