<template>
  <section class="fade">
    <div class="page-title-block">
      <h3>Сотрудники</h3>
    </div>

    <div class="actions-list">

      <v-card
        v-if="hasRoleAccess(permissionsRoutes.staff.employees)"
        class="actions-list__item"
        hover
        @click="$router.push('/staff/employees')"
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-account-cowboy-hat</v-icon>
        <p>Все Сотрудники</p>
      </v-card>

      <v-card
        class="actions-list__item"
        hover
        @click="changeRegisterPMModal(true)"
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-account-plus</v-icon>
        <p>Зарегистрировать РМ</p>
      </v-card>

      <!-- <v-card
        class="actions-list__item"
        hover
        @click="changeAddStaffModal(true)"
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-account-multiple</v-icon>
        <p>Добавить сотрудника</p>
      </v-card> -->

      <!-- <v-card
        class="actions-list__item"
        hover
        @click="$router.push('/staff/staff-workload')"
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-clipboard-edit</v-icon>
        <p>Загруженность сотрудников по проектам</p>
      </v-card> -->

      <v-card
        v-if="hasRoleAccess(permissionsRoutes.staff.specialistRates)"
        class="actions-list__item"
        hover
        @click="$router.push('/staff/specialist-rates')"
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-account-cowboy-hat</v-icon>
        <p>Ставки специалистов</p>
      </v-card>

      <v-card
        class="actions-list__item"
        hover
      >
        <v-icon
          x-large
          color="#FF7303"
        >mdi-human-handsup</v-icon>
        <p>Нерабочие дни / отпуск</p>
      </v-card>

    </div>

    <RegisterPMModal
      :isRegisterPMModal="isRegisterPMModal"
      @changeRegisterPMModal="changeRegisterPMModal"
    />

    <AddStaffModal
      :isAddStaffModal="isAddStaffModal"
      @changeAddStaffModal="changeAddStaffModal"
    />
  </section>
</template>

<script>
import RegisterPMModal from '@/views/staff/components/RegisterPMModal.vue';
import AddStaffModal from '@/views/staff/components/AddStaffModal.vue';

export default {
  name: 'StaffMain',

  data() {
    return {
      isRegisterPMModal: false,
      isAddStaffModal: false,
    };
  },

  methods: {
    changeRegisterPMModal(val) {
      this.isRegisterPMModal = val;
    },

    changeAddStaffModal(val) {
      this.isAddStaffModal = val;
    },
  },

  components: { RegisterPMModal, AddStaffModal },
};
</script>

<style scoped lang="scss">
.fade {
  padding: 24px 30px;
  .actions-list {
    display: flex;
    flex-wrap: wrap;
    &__item {
      background-color: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 270px;
      height: 270px;
      padding: 20px;
      margin: 0 30px 30px 0;
      border-radius: 24px;
      cursor: pointer;
      p {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 28px;
      }
    }
  }
}
</style>
