<template>
  <v-dialog
    :value="isRegisterPMModal"
    max-width="450"
    @click:outside="$emit('changeRegisterPMModal', false)"
    class="modal"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('changeRegisterPMModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title">Регистрация РМ</p>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mt-6"
      >
        <v-text-field
          v-model="user.name"
          :rules="nameRules"
          label="Имя"
          class="rounded-lg"
          required
          rounded
          outlined
        ></v-text-field>

        <v-text-field
          v-model="user.email"
          :rules="emailRules"
          label="E-mail"
          class="rounded-lg"
          required
          outlined
          rounded
        ></v-text-field>

        <div class="card-actions d-flex justify-center">
          <v-btn
            color="black"
            dark
            x-large
            class="modal__btn"
            @click="save"
          >
            Зарегистрировать
          </v-btn>
        </div>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import notification from '@/mixins/notification';

export default {
  name: 'RegisterPM',
  mixins: [notification],

  data() {
    return {
      valid: true,
      isPassword: false,
      user: {
        name: '',
        email: '',
      },
    };
  },

  methods: {
    ...mapActions('staff', ['createPM']),

    async save() {
      await this.$refs.form.validate();
      if (this.valid) {
        const res = await this.createPM(this.user);
        if (res.response?.status === 400) {
          if (res.response.data.email) {
            const error = res.response.data.email[0];
            if (error === 'Is Invalid') this.showErrorNotification('Некорректный email');
            if (error === 'Domain is invalid') this.showErrorNotification('Некорректный домен');
            if (error === 'Employee with this email already exists') this.showErrorNotification('Данный сотрудник уже зарегистрирован');
            if (error === 'PM with this email already exists') this.showErrorNotification('Данный сотрудник уже зарегистрирован');
            else this.showErrorNotification('Некорректный email');
          }
        } else {
          this.$refs.form.reset();
          this.$emit('changeRegisterPMModal', false);
        }
      }
    },
  },

  props: {
    isRegisterPMModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>
